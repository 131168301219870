import { defineMessages } from 'react-intl'

export const announcesMetaTitle: Record<
  string,
  { id: string; defaultMessage: string }
> = defineMessages({
  announces_meta_title_default_1_1: {
    id: 'announces.meta.title.default.1.1',
    defaultMessage:
      'Гей знакомства {location} - анонимные знакомства геев без регистрации Masked love',
  },
  announces_meta_title_goal_1_1: {
    id: 'announces.meta.title.goal.1.1',
    defaultMessage:
      'Гей знакомства {goal} {location} - анонимные знакомства геев без регистрации Masked love',
  },
  announces_meta_title_ageStrict_1_1: {
    id: 'announces.meta.title.ageStrict.1.1',
    defaultMessage:
      'Гей знакомства {location} {age} - анонимные знакомства геев без регистрации Masked love',
  },
  announces_meta_title_ageRange_1_1: {
    id: 'announces.meta.title.ageRange.1.1',
    defaultMessage:
      'Гей знакомства {location} {ageFrom}-{ageTo} {ageTo, plural, one {года} few {лет} many {лет} other {лет}} - анонимные знакомства геев без регистрации Masked love',
  },
  announces_meta_title_goal_ageStrict_1_1: {
    id: 'announces.meta.title.goal.ageStrict.1.1',
    defaultMessage:
      'Гей знакомства {goal} {location} {age} - анонимные знакомства геев без регистрации Masked love',
  },
  announces_meta_title_goal_ageRange_1_1: {
    id: 'announces.meta.title.goal.ageRange.1.1',
    defaultMessage:
      'Гей знакомства {location} {goal} {ageFrom}-{ageTo} {ageTo, plural, one {года} few {лет} many {лет} other {лет}} - анонимные знакомства геев без регистрации Masked love',
  },
  announces_meta_title_default_1_2: {
    id: 'announces.meta.title.default.1.2',
    defaultMessage:
      'Анонимные знакомства с девушками {location} без регистрации бесплатно - парень ищет девушку - доска объявлений Masked love',
  },
  announces_meta_title_goal_1_2: {
    id: 'announces.meta.title.goal.1.2',
    defaultMessage:
      'Анонимные знакомства с девушками {location} {goal} без регистрации бесплатно - доска объявлений Masked love',
  },
  announces_meta_title_ageStrict_1_2: {
    id: 'announces.meta.title.ageStrict.1.2',
    defaultMessage:
      'Анонимные знакомства с девушками {location} без регистрации бесплатно {age} - доска объявлений Masked love',
  },
  announces_meta_title_ageRange_1_2: {
    id: 'announces.meta.title.ageRange.1.2',
    defaultMessage:
      'Анонимные знакомства с девушками {location} без регистрации бесплатно {ageFrom}-{ageTo} {ageTo, plural, one {года} few {лет} many {лет} other {лет}} - доска объявлений Masked love',
  },
  announces_meta_title_goal_ageStrict_1_2: {
    id: 'announces.meta.title.goal.ageStrict.1.2',
    defaultMessage:
      'Анонимные знакомства с девушками {location} {goal} без регистрации бесплатно {age} - доска объявлений Masked love',
  },
  announces_meta_title_goal_ageRange_1_2: {
    id: 'announces.meta.title.goal.ageRange.1.2',
    defaultMessage:
      'Анонимные знакомства с девушками {location} {goal} без регистрации бесплатно {ageFrom}-{ageTo} {ageTo, plural, one {года} few {лет} many {лет} other {лет}} - доска объявлений Masked love',
  },

  announces_meta_title_default_2_2: {
    id: 'announces.meta.title.default.2.2',
    defaultMessage:
      'Лесби знакомства {location} - анонимные знакомства без регистрации Masked love',
  },
  announces_meta_title_goal_2_2: {
    id: 'announces.meta.title.goal.2.2',
    defaultMessage:
      'Лесби знакомства {goal} {location} - анонимные знакомства без регистрации Masked love',
  },
  announces_meta_title_ageStrict_2_2: {
    id: 'announces.meta.title.ageStrict.2.2',
    defaultMessage:
      'Лесби знакомства {location} {age} - анонимные знакомства без регистрации Masked love ',
  },
  announces_meta_title_ageRange_2_2: {
    id: 'announces.meta.title.ageRange.2.2',
    defaultMessage:
      'Лесби знакомства {location} {ageFrom}-{ageTo} {ageTo, plural, one {года} few {лет} many {лет} other {лет}} - анонимные знакомства без регистрации Masked love',
  },
  announces_meta_title_goal_ageStrict_2_2: {
    id: 'announces.meta.title.goal.ageStrict.2.2',
    defaultMessage:
      'Лесби знакомства {goal} {location} {age} - анонимные знакомства без регистрации Masked love',
  },
  announces_meta_title_goal_ageRange_2_2: {
    id: 'announces.meta.title.goal.ageRange.2.2',
    defaultMessage:
      'Лесби знакомства {goal} {location} {ageFrom}-{ageTo} {ageTo, plural, one {года} few {лет} many {лет} other {лет}} - анонимные знакомства без регистрации Masked love',
  },

  announces_meta_title_default_2_1: {
    id: 'announces.meta.title.default.2.1',
    defaultMessage:
      'Анонимные знакомства с мужчинами {location} без регистрации бесплатно - девушка ищет парня - доска объявлений Masked love',
  },
  announces_meta_title_goal_2_1: {
    id: 'announces.meta.title.goal.2.1',
    defaultMessage:
      'Анонимные знакомства с мужчинами {location} {goal} - доска объявлений без регистрации бесплатно Masked love',
  },
  announces_meta_title_ageStrict_2_1: {
    id: 'announces.meta.title.ageStrict.2.1',
    defaultMessage:
      'Анонимные знакомства с мужчинами {location} {age} - доска объявлений без регистрации бесплатно Masked love',
  },
  announces_meta_title_ageRange_2_1: {
    id: 'announces.meta.title.ageRange.2.1',
    defaultMessage:
      'Анонимные знакомства с мужчинами {location} {ageFrom}-{ageTo} {ageTo, plural, one {года} few {лет} many {лет} other {лет}} - доска объявлений без регистрации бесплатно Masked love',
  },
  announces_meta_title_goal_ageStrict_2_1: {
    id: 'announces.meta.title.goal.ageStrict.2.1',
    defaultMessage:
      'Анонимные знакомства с мужчинами {location} {goal} {age} - доска объявлений без регистрации бесплатно Masked love',
  },
  announces_meta_title_goal_ageRange_2_1: {
    id: 'announces.meta.title.goal.ageRange.2.1',
    defaultMessage:
      'Анонимные знакомства с мужчинами {location} {goal} {ageFrom}-{ageTo} {ageTo, plural, one {года} few {лет} many {лет} other {лет}} - доска объявлений без регистрации бесплатно Masked love',
  },

  announces_meta_title_default_1_3: {
    id: 'announces.meta.title.default.1.3',
    defaultMessage:
      'Знакомства с парами (МЖМ) - мужчина ищет пару {location} - би знакомства - доска объявлений без регистрации бесплатно Masked love',
  },
  announces_meta_title_goal_1_3: {
    id: 'announces.meta.title.goal.1.3',
    defaultMessage:
      'Знакомства с парами (МЖМ) - мужчина ищет пару {location} {goal} - би знакомства - доска объявлений без регистрации бесплатно Masked love',
  },
  announces_meta_title_ageStrict_1_3: {
    id: 'announces.meta.title.ageStrict.1.3',
    defaultMessage:
      'Знакомства с парами (МЖМ) - мужчина ищет пару {location} {age} - би знакомства - доска объявлений без регистрации бесплатно Masked love',
  },
  announces_meta_title_ageRange_1_3: {
    id: 'announces.meta.title.ageRange.1.3',
    defaultMessage:
      'Знакомства с парами (МЖМ) - мужчина ищет пару {location} {ageFrom}-{ageTo} {ageTo, plural, one {года} few {лет} many {лет} other {лет}} - би знакомства - доска объявлений без регистрации бесплатно Masked love',
  },
  announces_meta_title_goal_ageStrict_1_3: {
    id: 'announces.meta.title.goal.ageStrict.1.3',
    defaultMessage:
      'Знакомства с парами (МЖМ) - мужчина ищет пару {location} {goal} {age} - доска объявлений без регистрации бесплатно Masked love',
  },
  announces_meta_title_goal_ageRange_1_3: {
    id: 'announces.meta.title.goal.ageRange.1.3',
    defaultMessage:
      'Знакомства с парами (МЖМ) - мужчина ищет пару {location} {goal} {ageFrom}-{ageTo} {ageTo, plural, one {года} few {лет} many {лет} other {лет}} - доска объявлений без регистрации бесплатно Masked love',
  },

  announces_meta_title_default_3_1: {
    id: 'announces.meta.title.default.3.1',
    defaultMessage:
      'Знакомства с парами (МЖМ) - пара ищет мужчину {location} - би знакомства - доска объявлений без регистрации бесплатно Masked love',
  },
  announces_meta_title_goal_3_1: {
    id: 'announces.meta.title.goal.3.1',
    defaultMessage:
      'Знакомства с парами (МЖМ) - пара ищет мужчину {location} {goal} - би знакомства - доска объявлений без регистрации бесплатно Masked love',
  },
  announces_meta_title_ageStrict_3_1: {
    id: 'announces.meta.title.ageStrict.3.1',
    defaultMessage:
      'Знакомства с парами (МЖМ) - пара ищет мужчину {location} {age} - би знакомства - доска объявлений без регистрации бесплатно Masked love',
  },
  announces_meta_title_ageRange_3_1: {
    id: 'announces.meta.title.ageRange.3.1',
    defaultMessage:
      'Знакомства с парами (МЖМ) - пара ищет мужчину {location} {ageFrom}-{ageTo} {ageTo, plural, one {года} few {лет} many {лет} other {лет}} - би знакомства - доска объявлений без регистрации бесплатно Masked love',
  },
  announces_meta_title_goal_ageStrict_3_1: {
    id: 'announces.meta.title.goal.ageStrict.3.1',
    defaultMessage:
      'Знакомства с парами (МЖМ) - пара ищет мужчину {location} {goal} {age} - доска объявлений без регистрации бесплатно Masked love',
  },
  announces_meta_title_goal_ageRange_3_1: {
    id: 'announces.meta.title.goal.ageRange.3.1',
    defaultMessage:
      'Знакомства с парами (МЖМ) - пара ищет мужчину {location} {goal} {ageFrom}-{ageTo} {ageTo, plural, one {года} few {лет} many {лет} other {лет}} - доска объявлений без регистрации бесплатно Masked love',
  },

  announces_meta_title_default_2_3: {
    id: 'announces.meta.title.default.2.3',
    defaultMessage:
      'Знакомства с парами (ЖМЖ) - девушка ищет пару {location} - би знакомства - доска объявлений без регистрации бесплатно Masked love',
  },
  announces_meta_title_goal_2_3: {
    id: 'announces.meta.title.goal.2.3',
    defaultMessage:
      'Знакомства с парами (ЖМЖ) - девушка ищет пару {location} {goal} - би знакомства - доска объявлений без регистрации бесплатно Masked love',
  },
  announces_meta_title_ageStrict_2_3: {
    id: 'announces.meta.title.ageStrict.2.3',
    defaultMessage:
      'Знакомства с парами (ЖМЖ) - девушка ищет пару {location} {age} - би знакомства - доска объявлений без регистрации бесплатно Masked love',
  },
  announces_meta_title_ageRange_2_3: {
    id: 'announces.meta.title.ageRange.2.3',
    defaultMessage:
      'Знакомства с парами (ЖМЖ) - девушка ищет пару {location} {ageFrom}-{ageTo} {ageTo, plural, one {года} few {лет} many {лет} other {лет}} - би знакомства - доска объявлений без регистрации бесплатно Masked love',
  },
  announces_meta_title_goal_ageStrict_2_3: {
    id: 'announces.meta.title.goal.ageStrict.2.3',
    defaultMessage:
      'Знакомства с парами (ЖМЖ) - девушка ищет пару {location} {goal} {age} - доска объявлений без регистрации бесплатно Masked love',
  },
  announces_meta_title_goal_ageRange_2_3: {
    id: 'announces.meta.title.goal.ageRange.2.3',
    defaultMessage:
      'Знакомства с парами (ЖМЖ) - девушка ищет пару {location} {goal} {ageFrom}-{ageTo} {ageTo, plural, one {года} few {лет} many {лет} other {лет}} - доска объявлений без регистрации бесплатно Masked love',
  },

  announces_meta_title_default_3_2: {
    id: 'announces.meta.title.default.3.2',
    defaultMessage:
      'Знакомства с парами (ЖМЖ) - пара ищет девушку {location} - би знакомства - доска объявлений без регистрации бесплатно Masked love',
  },
  announces_meta_title_goal_3_2: {
    id: 'announces.meta.title.goal.3.2',
    defaultMessage:
      'Знакомства с парами (ЖМЖ) - пара ищет девушку {location} {goal} - би знакомства - доска объявлений без регистрации бесплатно Masked love',
  },
  announces_meta_title_ageStrict_3_2: {
    id: 'announces.meta.title.ageStrict.3.2',
    defaultMessage:
      'Знакомства с парами (ЖМЖ) - пара ищет девушку {location} {age} - би знакомства - доска объявлений без регистрации бесплатно Masked love',
  },
  announces_meta_title_ageRange_3_2: {
    id: 'announces.meta.title.ageRange.3.2',
    defaultMessage:
      'Знакомства с парами (ЖМЖ) - пара ищет девушку {location} {ageFrom}-{ageTo} {ageTo, plural, one {года} few {лет} many {лет} other {лет}} - би знакомства - доска объявлений без регистрации бесплатно Masked love',
  },
  announces_meta_title_goal_ageStrict_3_2: {
    id: 'announces.meta.title.goal.ageStrict.3.2',
    defaultMessage:
      'Знакомства с парами (ЖМЖ) - пара ищет девушку {location} {goal} {age} - доска объявлений без регистрации бесплатно Masked love',
  },
  announces_meta_title_goal_ageRange_3_2: {
    id: 'announces.meta.title.goal.ageRange.3.2',
    defaultMessage:
      'Знакомства с парами (ЖМЖ) - пара ищет девушку {location} {goal} {ageFrom}-{ageTo} {ageTo, plural, one {года} few {лет} many {лет} other {лет}} - доска объявлений без регистрации бесплатно Masked love',
  },

  announces_meta_title_default_3_3: {
    id: 'announces.meta.title.default.3.3',
    defaultMessage:
      'Знакомства с парами - пара ищет пару {location} - би знакомства - доска объявлений без регистрации бесплатно Masked love',
  },
  announces_meta_title_goal_3_3: {
    id: 'announces.meta.title.goal.3.3',
    defaultMessage:
      'Знакомства с парами - пара ищет пару {location} {goal} - би знакомства - доска объявлений без регистрации бесплатно Masked love',
  },
  announces_meta_title_ageStrict_3_3: {
    id: 'announces.meta.title.ageStrict.3.3',
    defaultMessage:
      'Знакомства с парами - пара ищет пару {location} {age} - би знакомства - доска объявлений без регистрации бесплатно Masked love',
  },
  announces_meta_title_ageRange_3_3: {
    id: 'announces.meta.title.ageRange.3.3',
    defaultMessage:
      'Знакомства с парами - пара ищет пару {location} {ageFrom}-{ageTo} {ageTo, plural, one {года} few {лет} many {лет} other {лет}} - би знакомства - доска объявлений без регистрации бесплатно Masked love',
  },
  announces_meta_title_goal_ageStrict_3_3: {
    id: 'announces.meta.title.goal.ageStrict.3.3',
    defaultMessage:
      'Знакомства с парами - пара ищет пару {location} {goal} {age} - доска объявлений без регистрации бесплатно Masked love',
  },
  announces_meta_title_goal_ageRange_3_3: {
    id: 'announces.meta.title.goal.ageRange.3.3',
    defaultMessage:
      'Знакомства с парами - пара ищет пару {location} {goal} {ageFrom}-{ageTo} {ageTo, plural, one {года} few {лет} many {лет} other {лет}} - доска объявлений без регистрации бесплатно Masked love',
  },
})
