import { defineMessages } from 'react-intl'

export const announcesMetaDescription: Record<
  string,
  { id: string; defaultMessage: string }
> = defineMessages({
  announces_meta_description_default_1_1: {
    id: 'announces.meta.description.default.1.1',
    defaultMessage:
      'Парень ищет парня для секса и отношений {location} - бесплатная доска реальных объявлений без регистрации. Геи знакомятся на masked.love.',
  },
  announces_meta_description_goal_1_1: {
    id: 'announces.meta.description.goal.1.1',
    defaultMessage:
      'Парень ищет парня {goal} {location} - бесплатная доска реальных объявлений без регистрации. Геи знакомятся анонимно на masked.love.',
  },
  announces_meta_description_ageStrict_1_1: {
    id: 'announces.meta.description.ageStrict.1.1',
    defaultMessage:
      'Парень ищет парня в возрасте {age} {location} - бесплатная доска реальных объявлений без регистрации. Геи знакомятся анонимно на masked.love.',
  },
  announces_meta_description_ageRange_1_1: {
    id: 'announces.meta.description.ageRange.1.1',
    defaultMessage:
      'Парень ищет парня {ageFrom}-{ageTo} {ageTo, plural, one {года} few {лет} many {лет} other {лет}} {location} - бесплатная доска реальных объявлений без регистрации. Геи знакомятся анонимно на masked.love.',
  },
  announces_meta_description_goal_ageStrict_1_1: {
    id: 'announces.meta.description.goal.ageStrict.1.1',
    defaultMessage:
      'Парень ищет парня {goal} в возрасте {age} {location} - бесплатная доска реальных объявлений без регистрации. Геи знакомятся анонимно на masked.love.',
  },
  announces_meta_description_goal_ageRange_1_1: {
    id: 'announces.meta.description.goal.ageRange.1.1',
    defaultMessage:
      'Парень ищет парня {ageFrom}-{ageTo} {ageTo, plural, one {года} few {лет} many {лет} other {лет}} {goal} {location} - бесплатная доска реальных объявлений без регистрации. Геи знакомятся анонимно на masked.love.',
  },
  announces_meta_description_default_1_2: {
    id: 'announces.meta.description.default.1.2',
    defaultMessage:
      'Парень ищет девушку или женщину - доска бесплатных объявлений сайта анонимных знакомств без регистрации {location}.',
  },
  announces_meta_description_goal_1_2: {
    id: 'announces.meta.description.goal.1.2',
    defaultMessage:
      'Парень ищет девушку или женщину {goal} - доска бесплатных объявлений сайта анонимных знакомств без регистрации {location}.',
  },
  announces_meta_description_ageStrict_1_2: {
    id: 'announces.meta.description.ageStrict.1.2',
    defaultMessage:
      'Парень ищет девушку или женщину {age} - доска бесплатных объявлений сайта анонимных знакомств без регистрации {location}.',
  },
  announces_meta_description_ageRange_1_2: {
    id: 'announces.meta.description.ageRange.1.2',
    defaultMessage:
      'Парень ищет девушку или женщину {ageFrom}-{ageTo} {ageTo, plural, one {года} few {лет} many {лет} other {лет}} - доска бесплатных объявлений сайта анонимных знакомств без регистрации {location}.',
  },
  announces_meta_description_goal_ageStrict_1_2: {
    id: 'announces.meta.description.goal.ageStrict.1.2',
    defaultMessage:
      'Парень ищет девушку или женщину {age} {goal} - доска бесплатных объявлений сайта анонимных знакомств без регистрации {location}.',
  },
  announces_meta_description_goal_ageRange_1_2: {
    id: 'announces.meta.description.goal.ageRange.1.2',
    defaultMessage:
      'Парень ищет девушку или женщину {ageFrom}-{ageTo} {ageTo, plural, one {года} few {лет} many {лет} other {лет}} {goal} - доска бесплатных объявлений сайта анонимных знакомств без регистрации {location}.',
  },

  announces_meta_description_default_2_2: {
    id: 'announces.meta.description.default.2.2',
    defaultMessage:
      'Девушка ищет девушку для секса и отношений {location} - бесплатная доска реальных объявлений без регистрации. Лесби знакомятся на masked.love.',
  },
  announces_meta_description_goal_2_2: {
    id: 'announces.meta.description.goal.2.2',
    defaultMessage:
      'Девушка ищет девушку {goal} {location} - бесплатная доска реальных объявлений без регистрации. Лесби знакомятся анонимно на masked.love.',
  },
  announces_meta_description_ageStrict_2_2: {
    id: 'announces.meta.description.ageStrict.2.2',
    defaultMessage:
      'Девушка ищет девушку в возрасте {age} {location} - бесплатная доска реальных объявлений без регистрации. Лесби знакомятся анонимно на masked.love.',
  },
  announces_meta_description_ageRange_2_2: {
    id: 'announces.meta.description.ageRange.2.2',
    defaultMessage:
      'Девушка ищет девушку {ageFrom}-{ageTo} {ageTo, plural, one {года} few {лет} many {лет} other {лет}} {location} - бесплатная доска реальных объявлений без регистрации. Лесби знакомятся анонимно на masked.love.',
  },
  announces_meta_description_goal_ageStrict_2_2: {
    id: 'announces.meta.description.goal.ageStrict.2.2',
    defaultMessage:
      'Девушка ищет девушку {goal} в возрасте {age} {location} - бесплатная доска реальных объявлений без регистрации. Лесби знакомятся анонимно на masked.love.',
  },
  announces_meta_description_goal_ageRange_2_2: {
    id: 'announces.meta.description.goal.ageRange.2.2',
    defaultMessage:
      'Девушка ищет девушку {ageFrom}-{ageTo} {ageTo, plural, one {года} few {лет} many {лет} other {лет}} {goal} {location} - бесплатная доска реальных объявлений без регистрации. Лесби знакомятся анонимно на masked.love.',
  },

  announces_meta_description_default_2_1: {
    id: 'announces.meta.description.default.2.1',
    defaultMessage:
      'Девушка ищет парня или мужчину - доска бесплатных объявлений сайта анонимных знакомств без регистрации {location}.',
  },
  announces_meta_description_goal_2_1: {
    id: 'announces.meta.description.goal.2.1',
    defaultMessage:
      'Девушка ищет парня или мужчину {goal} - доска бесплатных объявлений сайта анонимных знакомств без регистрации {location}.',
  },
  announces_meta_description_ageStrict_2_1: {
    id: 'announces.meta.description.ageStrict.2.1',
    defaultMessage:
      'Девушка ищет парня или мужчину {age} - доска бесплатных объявлений сайта анонимных знакомств без регистрации {location}.',
  },
  announces_meta_description_ageRange_2_1: {
    id: 'announces.meta.description.ageRange.2.1',
    defaultMessage:
      'Девушка ищет парня или мужчину {ageFrom}-{ageTo} {ageTo, plural, one {года} few {лет} many {лет} other {лет}} - доска бесплатных объявлений сайта анонимных знакомств без регистрации {location}.',
  },
  announces_meta_description_goal_ageStrict_2_1: {
    id: 'announces.meta.description.goal.ageStrict.2.1',
    defaultMessage:
      'Девушка ищет парня или мужчину {age} {goal} - доска бесплатных объявлений сайта анонимных знакомств без регистрации {location}.',
  },
  announces_meta_description_goal_ageRange_2_1: {
    id: 'announces.meta.description.goal.ageRange.2.1',
    defaultMessage:
      'Девушка ищет парня или мужчину {ageFrom}-{ageTo} {ageTo, plural, one {года} few {лет} many {лет} other {лет}} {goal} - доска бесплатных объявлений сайта анонимных знакомств без регистрации {location}.',
  },

  announces_meta_description_default_1_3: {
    id: 'announces.meta.description.default.3',
    defaultMessage:
      'Пара ищет мужчину или девушку на доске бесплатных би объявлений сайта анонимных знакомств без регистрации {location}.',
  },
  announces_meta_description_goal_1_3: {
    id: 'announces.meta.description.goal.3',
    defaultMessage:
      'Пара ищет мужчину или девушку {goal} на доске бесплатных би объявлений сайта анонимных знакомств без регистрации {location}.',
  },
  announces_meta_description_ageStrict_1_3: {
    id: 'announces.meta.description.ageStrict.3',
    defaultMessage:
      'Пара ищет мужчину или девушку в возрасте {age} на доске бесплатных би объявлений сайта анонимных знакомств без регистрации {location}.',
  },
  announces_meta_description_ageRange_1_3: {
    id: 'announces.meta.description.ageRange.3',
    defaultMessage:
      'Пара ищет мужчину или девушку в возрасте {ageFrom}-{ageTo} {ageTo, plural, one {года} few {лет} many {лет} other {лет}} на доске бесплатных би объявлений сайта анонимных знакомств без регистрации {location}.',
  },
  announces_meta_description_goal_ageStrict_1_3: {
    id: 'announces.meta.description.goal.ageStrict.3',
    defaultMessage:
      'Пара ищет мужчину или девушку {goal} в возрасте {age} на доске бесплатных би объявлений сайта анонимных знакомств без регистрации {location}.',
  },
  announces_meta_description_goal_ageRange_1_3: {
    id: 'announces.meta.description.goal.ageRange.3',
    defaultMessage:
      'Пара ищет мужчину или девушку {goal} в возрасте {ageFrom}-{ageTo} {ageTo, plural, one {года} few {лет} many {лет} other {лет}} на доске бесплатных би объявлений сайта анонимных знакомств без регистрации {location}.',
  },

  announces_meta_description_default_3_1: {
    id: 'announces.meta.description.default.3',
    defaultMessage:
      'Пара ищет мужчину или девушку на доске бесплатных би объявлений сайта анонимных знакомств без регистрации {location}.',
  },
  announces_meta_description_goal_3_1: {
    id: 'announces.meta.description.goal.3',
    defaultMessage:
      'Пара ищет мужчину или девушку {goal} на доске бесплатных би объявлений сайта анонимных знакомств без регистрации {location}.',
  },
  announces_meta_description_ageStrict_3_1: {
    id: 'announces.meta.description.ageStrict.3',
    defaultMessage:
      'Пара ищет мужчину или девушку в возрасте {age} на доске бесплатных би объявлений сайта анонимных знакомств без регистрации {location}.',
  },
  announces_meta_description_ageRange_3_1: {
    id: 'announces.meta.description.ageRange.3',
    defaultMessage:
      'Пара ищет мужчину или девушку в возрасте {ageFrom}-{ageTo} {ageTo, plural, one {года} few {лет} many {лет} other {лет}} на доске бесплатных би объявлений сайта анонимных знакомств без регистрации {location}.',
  },
  announces_meta_description_goal_ageStrict_3_1: {
    id: 'announces.meta.description.goal.ageStrict.3',
    defaultMessage:
      'Пара ищет мужчину или девушку {goal} в возрасте {age} на доске бесплатных би объявлений сайта анонимных знакомств без регистрации {location}.',
  },
  announces_meta_description_goal_ageRange_3_1: {
    id: 'announces.meta.description.goal.ageRange.3',
    defaultMessage:
      'Пара ищет мужчину или девушку {goal} в возрасте {ageFrom}-{ageTo} {ageTo, plural, one {года} few {лет} many {лет} other {лет}} на доске бесплатных би объявлений сайта анонимных знакомств без регистрации {location}.',
  },

  announces_meta_description_default_2_3: {
    id: 'announces.meta.description.default.3',
    defaultMessage:
      'Пара ищет мужчину или девушку на доске бесплатных би объявлений сайта анонимных знакомств без регистрации {location}.',
  },
  announces_meta_description_goal_2_3: {
    id: 'announces.meta.description.goal.3',
    defaultMessage:
      'Пара ищет мужчину или девушку {goal} на доске бесплатных би объявлений сайта анонимных знакомств без регистрации {location}.',
  },
  announces_meta_description_ageStrict_2_3: {
    id: 'announces.meta.description.ageStrict.3',
    defaultMessage:
      'Пара ищет мужчину или девушку в возрасте {age} на доске бесплатных би объявлений сайта анонимных знакомств без регистрации {location}.',
  },
  announces_meta_description_ageRange_2_3: {
    id: 'announces.meta.description.ageRange.3',
    defaultMessage:
      'Пара ищет мужчину или девушку в возрасте {ageFrom}-{ageTo} {ageTo, plural, one {года} few {лет} many {лет} other {лет}} на доске бесплатных би объявлений сайта анонимных знакомств без регистрации {location}.',
  },
  announces_meta_description_goal_ageStrict_2_3: {
    id: 'announces.meta.description.goal.ageStrict.3',
    defaultMessage:
      'Пара ищет мужчину или девушку {goal} в возрасте {age} на доске бесплатных би объявлений сайта анонимных знакомств без регистрации {location}.',
  },
  announces_meta_description_goal_ageRange_2_3: {
    id: 'announces.meta.description.goal.ageRange.3',
    defaultMessage:
      'Пара ищет мужчину или девушку {goal} в возрасте {ageFrom}-{ageTo} {ageTo, plural, one {года} few {лет} many {лет} other {лет}} на доске бесплатных би объявлений сайта анонимных знакомств без регистрации {location}.',
  },

  announces_meta_description_default_3_2: {
    id: 'announces.meta.description.default.3',
    defaultMessage:
      'Пара ищет мужчину или девушку на доске бесплатных би объявлений сайта анонимных знакомств без регистрации {location}.',
  },
  announces_meta_description_goal_3_2: {
    id: 'announces.meta.description.goal.3',
    defaultMessage:
      'Пара ищет мужчину или девушку {goal} на доске бесплатных би объявлений сайта анонимных знакомств без регистрации {location}.',
  },
  announces_meta_description_ageStrict_3_2: {
    id: 'announces.meta.description.ageStrict.3',
    defaultMessage:
      'Пара ищет мужчину или девушку в возрасте {age} на доске бесплатных би объявлений сайта анонимных знакомств без регистрации {location}.',
  },
  announces_meta_description_ageRange_3_2: {
    id: 'announces.meta.description.ageRange.3',
    defaultMessage:
      'Пара ищет мужчину или девушку в возрасте {ageFrom}-{ageTo} {ageTo, plural, one {года} few {лет} many {лет} other {лет}} на доске бесплатных би объявлений сайта анонимных знакомств без регистрации {location}.',
  },
  announces_meta_description_goal_ageStrict_3_2: {
    id: 'announces.meta.description.goal.ageStrict.3',
    defaultMessage:
      'Пара ищет мужчину или девушку {goal} в возрасте {age} на доске бесплатных би объявлений сайта анонимных знакомств без регистрации {location}.',
  },
  announces_meta_description_goal_ageRange_3_2: {
    id: 'announces.meta.description.goal.ageRange.3',
    defaultMessage:
      'Пара ищет мужчину или девушку {goal} в возрасте {ageFrom}-{ageTo} {ageTo, plural, one {года} few {лет} many {лет} other {лет}} на доске бесплатных би объявлений сайта анонимных знакомств без регистрации {location}.',
  },

  announces_meta_description_default_3_3: {
    id: 'announces.meta.description.default.3.3',
    defaultMessage:
      'Пара ищет пару на доске бесплатных би объявлений сайта анонимных знакомств без регистрации {location}.',
  },
  announces_meta_description_goal_3_3: {
    id: 'announces.meta.description.goal.3.3',
    defaultMessage:
      'Пара ищет пару {goal} на доске бесплатных би объявлений сайта анонимных знакомств без регистрации {location}.',
  },
  announces_meta_description_ageStrict_3_3: {
    id: 'announces.meta.description.ageStrict.3.3',
    defaultMessage:
      'Пара ищет пару в возрасте {age} на доске бесплатных би объявлений сайта анонимных знакомств без регистрации {location}.',
  },
  announces_meta_description_ageRange_3_3: {
    id: 'announces.meta.description.ageRange.3.3',
    defaultMessage:
      'Пара ищет пару в возрасте {ageFrom}-{ageTo} {ageTo, plural, one {года} few {лет} many {лет} other {лет}} на доске бесплатных би объявлений сайта анонимных знакомств без регистрации {location}.',
  },
  announces_meta_description_goal_ageStrict_3_3: {
    id: 'announces.meta.description.goal.ageStrict.3.3',
    defaultMessage:
      'Пара ищет пару {goal} в возрасте {age} на доске бесплатных би объявлений сайта анонимных знакомств без регистрации {location}.',
  },
  announces_meta_description_goal_ageRange_3_3: {
    id: 'announces.meta.description.goal.ageRange.3.3',
    defaultMessage:
      'Пара ищет пару {goal} в возрасте {ageFrom}-{ageTo} {ageTo, plural, one {года} few {лет} many {лет} other {лет}} на доске бесплатных би объявлений сайта анонимных знакомств без регистрации {location}.',
  },
})
