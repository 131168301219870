import { defineMessages } from 'react-intl'

export const announcesPageTitle: Record<
  string,
  { id: string; defaultMessage: string }
> = defineMessages({
  announces_page_main_title_default_1_1: {
    id: 'announce.page.main.title.default.1.1',
    defaultMessage: 'Гей знакомства {location}',
  },
  announces_page_main_title_goal_1_1: {
    id: 'announce.page.main.title.goal.1.1',
    defaultMessage: 'Гей знакомства {goal} {location}',
  },
  announces_page_main_title_ageStrict_1_1: {
    id: 'announce.page.main.title.ageStrict.1.1',
    defaultMessage: 'Гей знакомства в возрасте {age} {location}',
  },
  announces_page_main_title_ageRange_1_1: {
    id: 'announce.page.main.title.ageRange.1.1',
    defaultMessage:
      'Гей знакомства от {ageFrom} до {ageTo} {ageTo, plural, one {года} few {лет} many {лет} other {лет}} {location}',
  },
  announces_page_main_title_goal_ageStrict_1_1: {
    id: 'announce.page.main.title.goal.ageStrict.1.1',
    defaultMessage: 'Гей знакомства {goal} в возрасте {age} {location}',
  },
  announces_page_main_title_goal_ageRange_1_1: {
    id: 'announce.page.main.title.goal.ageRange.1.1',
    defaultMessage:
      'Гей знакомства {goal} от {ageFrom} до {ageTo} {ageTo, plural, one {года} few {лет} many {лет} other {лет}} {location}',
  },

  announces_page_main_title_default_1_2: {
    id: 'announce.page.main.title.default.1.2',
    defaultMessage: 'Знакомства с девушками {location}',
  },
  announces_page_main_title_goal_1_2: {
    id: 'announce.page.main.title.goal.1.2',
    defaultMessage: 'Знакомства с девушками {goal} {location}',
  },
  announces_page_main_title_ageStrict_1_2: {
    id: 'announce.page.main.title.ageStrict.1.2',
    defaultMessage: 'Знакомства с девушками в возрасте {age} {location}',
  },
  announces_page_main_title_ageRange_1_2: {
    id: 'announce.page.main.title.ageRange.1.2',
    defaultMessage:
      'Знакомства с девушками от {ageFrom} до {ageTo} {ageTo, plural, one {года} few {лет} many {лет} other {лет}} {location}',
  },
  announces_page_main_title_goal_ageStrict_1_2: {
    id: 'announce.page.main.title.goal.ageStrict.1.2',
    defaultMessage: 'Знакомства с девушками {goal} в возрасте {age} {location}',
  },
  announces_page_main_title_goal_ageRange_1_2: {
    id: 'announce.page.main.title.goal.ageRange.1.2',
    defaultMessage:
      'Знакомства с девушками {goal} от {ageFrom} до {ageTo} {ageTo, plural, one {года} few {лет} many {лет} other {лет}} {location}',
  },

  announces_page_main_title_default_2_2: {
    id: 'announce.page.main.title.default.2.2',
    defaultMessage: 'Лесби знакомства {location}',
  },
  announces_page_main_title_goal_2_2: {
    id: 'announce.page.main.title.goal.2.2',
    defaultMessage: 'Лесби знакомства {goal} {location}',
  },
  announces_page_main_title_ageStrict_2_2: {
    id: 'announce.page.main.title.ageStrict.2.2',
    defaultMessage: 'Лесби знакомства в возрасте {age} {location}',
  },
  announces_page_main_title_ageRange_2_2: {
    id: 'announce.page.main.title.ageRange.2.2',
    defaultMessage:
      'Лесби знакомства от {ageFrom} до {ageTo} {ageTo, plural, one {года} few {лет} many {лет} other {лет}} {location}',
  },
  announces_page_main_title_goal_ageStrict_2_2: {
    id: 'announce.page.main.title.goal.ageStrict.2.2',
    defaultMessage: 'Лесби знакомства {goal} в возрасте {age} {location}',
  },
  announces_page_main_title_goal_ageRange_2_2: {
    id: 'announce.page.main.title.goal.ageRange.2.2',
    defaultMessage:
      'Лесби знакомства {goal} от {ageFrom} до {ageTo} {ageTo, plural, one {года} few {лет} many {лет} other {лет}} {location}',
  },

  announces_page_main_title_default_2_1: {
    id: 'announce.page.main.title.default.2.1',
    defaultMessage: 'Знакомства с мужчинами {location}',
  },
  announces_page_main_title_goal_2_1: {
    id: 'announce.page.main.title.goal.2.1',
    defaultMessage: 'Знакомства с мужчинами {goal} {location}',
  },
  announces_page_main_title_ageStrict_2_1: {
    id: 'announce.page.main.title.ageStrict.2.1',
    defaultMessage: 'Знакомства с мужчинами в возрасте {age} {location}',
  },
  announces_page_main_title_ageRange_2_1: {
    id: 'announce.page.main.title.ageRange.2.1',
    defaultMessage:
      'Знакомства с мужчинами от {ageFrom} до {ageTo} {ageTo, plural, one {года} few {лет} many {лет} other {лет}} {location}',
  },
  announces_page_main_title_goal_ageStrict_2_1: {
    id: 'announce.page.main.title.goal.ageStrict.2.1',
    defaultMessage: 'Знакомства с мужчинами {goal} в возрасте {age} {location}',
  },
  announces_page_main_title_goal_ageRange_2_1: {
    id: 'announce.page.main.title.goal.ageRange.2.1',
    defaultMessage:
      'Знакомства с  мужчинами {goal} от {ageFrom} до {ageTo} {ageTo, plural, one {года} few {лет} many {лет} other {лет}} {location}',
  },

  announces_page_main_title_default_1_3: {
    id: 'announce.page.main.title.default.1.3',
    defaultMessage: 'Знакомства с парами (МЖМ) - мужчина ищет пару {location}',
  },
  announces_page_main_title_goal_1_3: {
    id: 'announce.page.main.title.goal.1.3',
    defaultMessage:
      'Знакомства с парами (МЖМ) - мужчина ищет пару {goal} {location}',
  },
  announces_page_main_title_ageStrict_1_3: {
    id: 'announce.page.main.title.ageStrict.1.3',
    defaultMessage:
      'Знакомства с парами (МЖМ) - мужчина ищет пару в возрасте {age} {location}',
  },
  announces_page_main_title_ageRange_1_3: {
    id: 'announce.page.main.title.ageRange.1.3',
    defaultMessage:
      'Знакомства с парами (МЖМ) - мужчина ищет пару в возрасте от {ageFrom} до {ageTo} {ageTo, plural, one {года} few {лет} many {лет} other {лет}} {location}',
  },
  announces_page_main_title_goal_ageStrict_1_3: {
    id: 'announce.page.main.title.goal.ageStrict.1.3',
    defaultMessage:
      'Знакомства с парами (МЖМ) - мужчина ищет пару {goal} в возрасте {age} {location}',
  },
  announces_page_main_title_goal_ageRange_1_3: {
    id: 'announce.page.main.title.goal.ageRange.1.3',
    defaultMessage:
      'Знакомства с парами (МЖМ) - мужчина ищет пару {goal} в возрасте от {ageFrom} до {ageTo} {ageTo, plural, one {года} few {лет} many {лет} other {лет}} {location}',
  },

  announces_page_main_title_default_3_1: {
    id: 'announce.page.main.title.default.3.1',
    defaultMessage: 'Знакомства с парами (МЖМ) - пара ищет мужчину {location}',
  },
  announces_page_main_title_goal_3_1: {
    id: 'announce.page.main.title.goal.3.1',
    defaultMessage:
      'Знакомства с парами (МЖМ) - пара ищет мужчину {goal} {location}',
  },
  announces_page_main_title_ageStrict_3_1: {
    id: 'announce.page.main.title.ageStrict.3.1',
    defaultMessage:
      'Знакомства с парами (МЖМ) - пара ищет мужчину в возрасте {age} {location}',
  },
  announces_page_main_title_ageRange_3_1: {
    id: 'announce.page.main.title.ageRange.3.1',
    defaultMessage:
      'Знакомства с парами (МЖМ) - пара ищет мужчину в возрасте от {ageFrom} до {ageTo} {ageTo, plural, one {года} few {лет} many {лет} other {лет}} {location}',
  },
  announces_page_main_title_goal_ageStrict_3_1: {
    id: 'announce.page.main.title.goal.ageStrict.3.1',
    defaultMessage:
      'Знакомства с парами (МЖМ) - пара ищет мужчину {goal} в возрасте {age} {location}',
  },
  announces_page_main_title_goal_ageRange_3_1: {
    id: 'announce.page.main.title.goal.ageRange.3.1',
    defaultMessage:
      'Знакомства с парами (МЖМ) - пара ищет мужчину {goal} в возрасте от {ageFrom} до {ageTo} {ageTo, plural, one {года} few {лет} many {лет} other {лет}} {location}',
  },

  announces_page_main_title_default_2_3: {
    id: 'announce.page.main.title.default.2.3',
    defaultMessage: 'Знакомства с парами (ЖМЖ) - девушка ищет пару {location}',
  },
  announces_page_main_title_goal_2_3: {
    id: 'announce.page.main.title.goal.2.3',
    defaultMessage:
      'Знакомства с парами (ЖМЖ) - девушка ищет пару {goal} {location}',
  },
  announces_page_main_title_ageStrict_2_3: {
    id: 'announce.page.main.title.ageStrict.2.3',
    defaultMessage:
      'Знакомства с парами (ЖМЖ) - девушка ищет пару в возрасте {age} {location}',
  },
  announces_page_main_title_ageRange_2_3: {
    id: 'announce.page.main.title.ageRange.2.3',
    defaultMessage:
      'Знакомства с парами (ЖМЖ) - девушка ищет пару в возрасте от {ageFrom} до {ageTo} {ageTo, plural, one {года} few {лет} many {лет} other {лет}} {location}',
  },
  announces_page_main_title_goal_ageStrict_2_3: {
    id: 'announce.page.main.title.goal.ageStrict.2.3',
    defaultMessage:
      'Знакомства с парами (ЖМЖ) - девушка ищет пару {goal} в возрасте {age} {location}',
  },
  announces_page_main_title_goal_ageRange_2_3: {
    id: 'announce.page.main.title.goal.ageRange.2.3',
    defaultMessage:
      'Знакомства с парами (ЖМЖ) - девушка ищет пару {goal} в возрасте от {ageFrom} до {ageTo} {ageTo, plural, one {года} few {лет} many {лет} other {лет}} {location}',
  },

  announces_page_main_title_default_3_2: {
    id: 'announce.page.main.title.default.3.2',
    defaultMessage: 'Знакомства с парами (ЖМЖ) - пара ищет девушку {location}',
  },
  announces_page_main_title_goal_3_2: {
    id: 'announce.page.main.title.goal.3.2',
    defaultMessage:
      'Знакомства с парами (ЖМЖ) - пара ищет девушку {goal} {location}',
  },
  announces_page_main_title_ageStrict_3_2: {
    id: 'announce.page.main.title.ageStrict.3.2',
    defaultMessage:
      'Знакомства с парами (ЖМЖ) - пара ищет девушку в возрасте {age} {location}',
  },
  announces_page_main_title_ageRange_3_2: {
    id: 'announce.page.main.title.ageRange.3.2',
    defaultMessage:
      'Знакомства с парами (ЖМЖ) - пара ищет девушку в возрасте от {ageFrom} до {ageTo} {ageTo, plural, one {года} few {лет} many {лет} other {лет}} {location}',
  },
  announces_page_main_title_goal_ageStrict_3_2: {
    id: 'announce.page.main.title.goal.ageStrict.3.2',
    defaultMessage:
      'Знакомства с парами (ЖМЖ) - пара ищет девушку {goal} в возрасте {age} {location}',
  },
  announces_page_main_title_goal_ageRange_3_2: {
    id: 'announce.page.main.title.goal.ageRange.3.2',
    defaultMessage:
      'Знакомства с парами (ЖМЖ) - пара ищет девушку {goal} в возрасте от {ageFrom} до {ageTo} {ageTo, plural, one {года} few {лет} many {лет} other {лет}} {location}',
  },

  announces_page_main_title_default_3_3: {
    id: 'announce.page.main.title.default.3.3',
    defaultMessage: 'Знакомства с парами - пара ищет пару {location}',
  },
  announces_page_main_title_goal_3_3: {
    id: 'announce.page.main.title.goal.3.3',
    defaultMessage: 'Знакомства с парами - пара ищет пару {goal} {location}',
  },
  announces_page_main_title_ageStrict_3_3: {
    id: 'announce.page.main.title.ageStrict.3.3',
    defaultMessage:
      'Знакомства с парами - пара ищет пару в возрасте {age} {location}',
  },
  announces_page_main_title_ageRange_3_3: {
    id: 'announce.page.main.title.ageRange.3.3',
    defaultMessage:
      'Знакомства с парами - пара ищет пару в возрасте от {ageFrom} до {ageTo} {ageTo, plural, one {года} few {лет} many {лет} other {лет}} {location}',
  },
  announces_page_main_title_goal_ageStrict_3_3: {
    id: 'announce.page.main.title.goal.ageStrict.3.3',
    defaultMessage:
      'Знакомства с парами - пара ищет пару {goal} в возрасте {age} {location}',
  },
  announces_page_main_title_goal_ageRange_3_3: {
    id: 'announce.page.main.title.goal.ageRange.3.3',
    defaultMessage:
      'Знакомства с парами - пара ищет пару {goal} в возрасте от {ageFrom} до {ageTo} {ageTo, plural, one {года} few {лет} many {лет} other {лет}} {location}',
  },
})
