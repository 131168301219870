import {
  AppDispatch,
  AsyncAction,
  AsyncThunkAction,
  AsyncThunkAction3,
} from 'actions/actions.types'
import { Cookies } from 'constants/cookie'
import { FieldName, FieldValueTypes } from 'actions/announce/announceForm.types'
import { NoticePaths } from 'components/page/notice/components.notice.paths'
import { Paths } from 'components/components.paths'
import { SUBMIT_ANSWER } from 'actions/captchaInlineActions'
import { definitions } from 'api/generated/announcement_reply'
import { mergeAllUrls } from 'functions/mergeAllUrls'

import { replace } from 'actions/system/router'
import { sendAnnounceAnswerFormApi } from 'api/announce/sendAnnounceAnswerFormApi'
import { updateAnnounceAnswerCookie } from 'client/updateCookieForm'
import { updateCookiesAction } from 'actions/system/setBootstrapDataAction'

export const UPDATE_ANNOUNCE_ANSWER = 'UPDATE_ANNOUNCE_ANSWER' as const

export interface UpdateAnnounceAnswerAction {
  type: typeof UPDATE_ANNOUNCE_ANSWER
  name: string
  value: FieldValueTypes
}

export const updateAnnounceAnswerAction = (
  name: FieldName,
  value: FieldValueTypes
): UpdateAnnounceAnswerAction => ({
  name,
  value,
  type: UPDATE_ANNOUNCE_ANSWER,
})

export interface AnnounceAnswerFormAction
  extends AsyncAction<definitions['ReplyResult']> {
  type: typeof SUBMIT_ANSWER
}

const sendAnswerAnnouncePlainAction =
  (
    id: number,
    answerEmail: string,
    answerText: string
  ): AsyncThunkAction3<ReturnType<typeof sendAnnounceAnswerFormApi>> =>
  (dispatch) => {
    return dispatch({
      type: SUBMIT_ANSWER,
      promise: () => sendAnnounceAnswerFormApi(id, answerText, answerEmail),
    })
  }

export const sendAnnounceAnswerAction =
  (
    id: number
  ): AsyncThunkAction3<Promise<ReturnType<typeof sendAnnounceAnswerFormApi>>> =>
  async (dispatch, getState) => {
    const appDispatch = dispatch as AppDispatch
    const {
      announceAnswerForm: { answerText },
      authorization: { verifiedEmail },
    } = getState()

    const response = await appDispatch(
      sendAnswerAnnouncePlainAction(id, verifiedEmail, answerText)
    )

    return response
  }

export const announceAnswerResponseAction =
  (
    locale: string,
    announcePostAnswer: definitions['ReplyResult']
  ): AsyncThunkAction<unknown> =>
  (dispatch) => {
    updateAnnounceAnswerCookie({ email: announcePostAnswer.email })
    dispatch(
      updateCookiesAction(Cookies.announceAnswerForm, {
        email: announcePostAnswer.email,
      })
    )

    if (announcePostAnswer.premiumOrderId) {
      dispatch(
        replace(
          mergeAllUrls(
            locale,
            Paths.showcasePremiumPart,
            String(announcePostAnswer.premiumOrderId)
          )
        )
      )
    } else if (announcePostAnswer.shouldBePaid) {
      // old billing flow now is unsupported
      dispatch(replace(Paths.paymentUnavailable))
    } else {
      dispatch(replace(mergeAllUrls(locale, NoticePaths.sendAnswer)))
    }
  }

export const UPDATE_ANNOUNCE_ID = 'UPDATE_ANNOUNCE_ID' as const

export const updateAnnounceAnswerIdAction = (announceId: number) => ({
  type: UPDATE_ANNOUNCE_ID,
  announceId,
})

export type AnnounceAnswerTypes = AnnounceAnswerFormAction
