import React from 'react'
import bannerBackground from './icons/BannerBackground.png'
import bannerBackgroundMobile from './icons/BannerBackgroundMobile.png'
import verifiedAnnouncementWhite from './icons/verifiedAnnouncementWhite.png'
import { ColorsTheme } from 'constants/announces/ColorTheme'
import { FormattedMessage } from 'react-intl'
import { Image } from 'components/presentational/Image'
import { Indent, SizeViewport } from 'constants/sizes'
import { ItemOrdinary } from '../List'
import { Paths } from 'components/components.paths'
import { ptSerifProNarrowFontFamily } from 'constants/fontsFamily'
import { push } from 'actions/system/router'
import { styled } from 'linaria/react'
import { useAppDispatch } from 'hooks/useAppDispatch'

export const AnnouncesListLifenessBanner = () => {
  const dispatch = useAppDispatch()

  const handleVerifyClick = () => {
    dispatch(push(Paths.verify))
  }

  return (
    <LifenessBannerWrapper as="li" data-name="special-banner">
      <BannerContent>
        <LifenessBannerText>
          <FormattedMessage
            id="lifeness.banner.description"
            defaultMessage="Это мой соблазн, это моя страсть и это моя безопасность."
          />
        </LifenessBannerText>
        <LifenessBannerButton onClick={handleVerifyClick}>
          <FormattedMessage id="lifeness.banner.button" defaultMessage="Хочу" />
          <Image
            key="verifiedAnnouncement"
            src={verifiedAnnouncementWhite}
            width="18"
            height="18"
            loading="lazy"
            alt="verified announcement"
          />
        </LifenessBannerButton>
      </BannerContent>
    </LifenessBannerWrapper>
  )
}

const BannerContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 280px;
  font-family: ${ptSerifProNarrowFontFamily}, serif;
  gap: ${Indent.indent16}px;
  z-index: 1;
`

const LifenessBannerWrapper = styled(ItemOrdinary)`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  padding: ${Indent.indent20}px;
  height: 144px;
  overflow: hidden;
  border: none;
  background-image: url(${bannerBackground});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @media screen and (max-width: ${SizeViewport.phone}px) {
    height: 162px;
    background-image: url(${bannerBackgroundMobile});
  }
`

const LifenessBannerText = styled.div`
  color: ${ColorsTheme.black};
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  z-index: 1;
`
const LifenessBannerButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 141px;
  padding: 11px 16px;
  gap: 10px;

  background-color: ${ColorsTheme.buttonBg};
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  z-index: 1;

  font-family: ${ptSerifProNarrowFontFamily}, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  text-align: center;

  &:hover {
    opacity: 0.9;
  }
`
